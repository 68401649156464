import React, { useState } from 'react';
import { isNil } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import Books from './books';
import Modal from '../modal';
import LearnMore from '../learnMore';

const BooksContainer = (props) => {
  const { staticText, language } = props;
  const books = language === 'eng' ? props?.pageContext?.books : props?.pageContext?.books_es;
  const [modal, setModal] = useState(null);

  return (
    <div className="books-inner-container">
      <Modal
        open={!isNil(modal)}
        onClose={() => {
          setModal(null);
        }}
      >
        <LearnMore
          open={!isNil(modal)}
          onClose={() => {
            setModal(null);
          }}
          book={modal}
          language={language}
        />
      </Modal>
      <div className="mb-4">
        <h2 className="fnt-primary fnt-evolventa bold fnt-r-22 mb-1">{staticText.title}</h2>
        <h2 className="fnt-black fnt-evolventa bold fnt-r-22">{staticText.sub_title}</h2>
      </div>
      <h3 className="fnt-jomolhari fnt-r-38 pr-10">{staticText.lg_title}</h3>
      <div className="img-grid-container">
        <Row>
          {books.map((book, index) => (
            <Col md={3} key={`book-${index}`}>
              <Books
                book={book}
                onClick={(id) => {
                  setModal(book);
                }}
                language={language}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default BooksContainer;
