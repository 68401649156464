import React, { useState } from 'react';
import { Form, Image, Button, Row, Col, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { BsDot } from 'react-icons/bs';
import * as States from '../../data/states/states.json';
import logo from '../../assets/logo.png';
import { requestMoreInfo } from '../../actions/requestInfo';
import './style.scss';

const RequestForm = (props) => {
  const { staticText, language, from } = props;
  const dateToday = new Date().toISOString().slice(0, 10);
  const initialFormData = {
    fname: '',
    lname: '',
    address: '',
    aptno: '',
    phone: '',
    city: '',
    email: '',
    state: '',
    zip: '',
    from: 'ccc',
    free_book: from === 'freeBook' ? 'freeBook' : '',
    date: dateToday,
  };
  const [modalShow, setModalShow] = useState(false);
  const [modalShowNon, setModalShowNon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [newsLetter, setNewsLetter] = useState(true);

  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState([]);
  const [validated, setValidated] = useState(false);

  useState(() => {
    var states = [];
    const res = Object.assign({}, States.states);
    states = Object.keys(res).map((k) => ({ [k]: res[k] }));
    setData(states);
  }, []);

  const showModalhandler = () => {
    setModalShow(true);
    setTimeout(function () {
      window.location.assign('https://familyhomechristianbooks.com/children-s-century-classics-set.html');
    }, 3000);
  };
  const showModalhandlerNon = () => {
    setModalShowNon(true);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setIsLoading(true);
        const response = await requestMoreInfo(formData);
        const data = await response.json();
        if (data.data.status === 'redirect') {
          showModalhandler();
        } else if (data.data.status === true) {
          showModalhandlerNon();
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
      setFormData(initialFormData);
    }
  };

  const inputChangeHandler = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFormData((prevStore) => {
      return {
        ...prevStore,
        [name]: value,
      };
    });
  };

  const ModalRedect = (props) => {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Image src={logo} />
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: '#767A82' }}>
            Thank you for submitting your request for more information. You are being redirected to our partner site
            where you can purchase these books today.
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: '#6993bb' }}>
          <img src="https://familyhomechristianbooks.com/skin/frontend/default/theme531/images/logo.png" />
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalNonRedect = (props) => {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Image src={logo} />
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: '#767A82' }}>
            Thank you for submitting your request for more information. Someone will be in contact with you shortly.
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: '#6993bb' }}>
          <img src="https://familyhomechristianbooks.com/skin/frontend/default/theme531/images/logo.png" />
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="request-more-info-form">
      <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>
              {staticText.first_name} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="fname" value={formData.fname} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>
              {staticText.last_name} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="lname" value={formData.lname} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom05">
            <Form.Label>
              {staticText.phone} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="phone" value={formData.phone} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom04">
            <Form.Label>
              {staticText.email} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="email" name="email" value={formData.email} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">
              This Field is required and must be a valid email
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>
              {staticText.address} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="address" value={formData.address} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom06">
            <Form.Label>{staticText.unit} </Form.Label>
            <Form.Control type="text" name="aptno" value={formData.aptno} onChange={inputChangeHandler} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom07">
            <Form.Label>
              {staticText.city} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="city" value={formData.city} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom08">
            <Form.Label>
              {staticText.state} <BsDot size={36} />
            </Form.Label>
            <Form.Control
              required
              as="select"
              type="text"
              name="state"
              value={formData.state}
              onChange={inputChangeHandler}
            >
              <option value="">--</option>
              {data.map((opt, index) => (
                <option value={Object.keys(opt)[0]} key={`option-${index}`}>
                  {Object.values(opt)[0]}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom09">
            <Form.Label>
              {staticText.zip} <BsDot size={36} />
            </Form.Label>
            <Form.Control required type="text" name="zip" value={formData.zip} onChange={inputChangeHandler} />
            <Form.Control.Feedback type="invalid">This Field is required</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            required
            label={
              <>
                {staticText.agreement_a} {<a href="#">{staticText.agreement_b}</a>}
              </>
            }
            feedback="You must agree before submitting."
            checked={privacy}
            onChange={() => {
              setPrivacy(!privacy);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            checked={newsLetter}
            label={staticText.newsletter}
            onChange={() => {
              setNewsLetter(!newsLetter);
            }}
          />
        </Form.Group>
        <Button
          className="req-btn bg-prime fnt-evolventa fnt-white fnt-14 bold border-none pr-4 pl-4 pt-3 pb-3"
          type="submit"
        >
          {from === 'freeBook'
            ? language === 'eng'
              ? 'Get Free Book'
              : 'Enviar formulario'
            : language === 'eng'
            ? 'Submit form'
            : 'Enviar formulario'}

          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ml-2" />
          )}
        </Button>
      </Form>
      <ModalRedect show={modalShow} onHide={() => setModalShow(false)} />
      <ModalNonRedect show={modalShowNon} onHide={() => setModalShowNon(false)} />
    </div>
  );
};

export default RequestForm;
