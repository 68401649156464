import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const contents = {
  'Bible Reference Century Classics': {
    desc: 'BIBLE REFERENCE CENTURY CLASSICS COLLECTION of five volumes, which spans time from before the earth’s beginning to the ultimate fulfillment of God’s plan for His people, is simply the most beautifully written and insightful Bible commentary ever produced. As such, we thought it only appropriate to over these marvelous books in this beautifully designed hardcover format that will become a treasury of wisdom for generations to come.',
    addInfo: (
      <div className="addInfo">
        <p>
          <strong>PATRIARCHS AND PROPHETS</strong> is the book of beginnings, vividly describing the creation of the
          universe as well as mankind, the beginning of evil in the world, and the great panorama of patriarchal life
          that led up to God’s dramatic dealings with Israel through the kingdom of David. This is one of the only books
          available anywhere that can reveal with accuracy and authority the beginnings of the great war between Christ
          and Satan.
        </p>
        <p>
          <strong>PROPHETS AND KINGS</strong> begins with the reign of King Solomon and recounts the stories of the
          great men and women of the Bible to the first advent of Jesus Christ—Elijah, Daniel, Isaiah, Jeremiah, and
          many others. It tells the epic story of Israel’s triumphs, defeats, backsliding, captivity, and reformation.
        </p>
        <p>
          <strong>THE DESIRE OF AGES</strong> is the book about the Man who stands at the center of all human history.
          The life of Jesus Christ is without parallel anywhere. No one else has had such a profound influence on the
          people of this world. He alone can meet the inexpressible desire in each of our lives for happiness and
          meaning.
        </p>
        <p>
          <strong>THE ACTS OF THE APOSTLES</strong> gives the vivid, reliable account of the founding of the Christian
          church and the awesome power of the Holy Spirit. We are invited to witness the enormous power of the early
          church, then to experience the same for ourselves.
        </p>
        <p>
          <strong>THE GREAT CONTROVERSY</strong> unveils the often unseen battle between Christ and Satan and looks
          ahead to the day when sin’s reign will end and Jesus will rule as King of kings and Lord of lords. Here is the
          authoritative answer to the confusion and despair of this tense age. This may be the most important book you
          will ever read.
        </p>
        <p>
          We commend these volumes to all who take pleasure in studying the great plans and purposes of God for human
          redemption as they have been clearly unfolded in His Word, the Bible.
        </p>
      </div>
    ),
  },
  'Family Bible': {
    desc: 'The Keepsake Family Bible is no ordinary Bible—it is a beautiful heirloom that your family will treasure for generations. The words in this sacred book will provide your family members with healing and hope. Reading your Bible for morning and evening worship will fill your home with love as well as provide your family with joy, stability, direction, and meaning. It will be the most important book that you will ever own.',
    addInfo: (
      <div className="addInfo family">
        <p>This Bible Includes</p>
        <ul>
          <li>More than 300 beautiful works of art</li>
          <li>Maps, charts, and historical outlines provide deeper understanding of the text</li>
          <li>A large picture timeline unfolds to nearly three feet</li>
          <li>High-quality materials and durable finishing ensure years of use and exploration</li>
          <li>Two ribbon markers help you keep your place</li>
          <li>
            More than 180 pages of study materials help shape and enhance your time in the Word, including: a 27-topic
            Bible study, a harmony of the Gospels, a chronology of the Bible, the miracles of Jesus, a Bible reading
            plan for the year, introductions to each book, and more
          </li>
        </ul>
      </div>
    ),
  },
  "Miss Brenda's Bedtime Stories": {
    desc: 'Each hardcover volume of this set of bedtime stories by Miss Brenda, includes more than 25 delightful, true stories to help children learn about forgiveness, answered prayer, honesty, and more. Perfect for family worships, Sabbath School, school worships, or just for fun, these stories will warm your heart and draw your children closer to Jesus! Full color images throughout!',
    addInfo: (
      <div className="addInfo bedtime">
        <Row>
          <Col md={4}>
            <Image src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/elementor/thumbs/mbbs-vol1-omi199kzv4tg2lb8oyj2od55sn31ptxcwxsbs6agwo.jpg" />
          </Col>
          <Col md={8}>
            <p className="vol-title">
              <strong>Miss Brenda’s Bedtime Stories (VOL 1)</strong>
              <br />
              <em>Hardcover | 160 pages each | Full Color</em>
            </p>
            <p className="mt-3">
              Miss Brenda’s Bedtime Stories, <strong>Volume One</strong>, is a collection of more than twenty-five
              delightful stories—stories that you will never forget. You will encounter “Tackle Tag Disaster,” “Snakes
              Alive” and “Trapped in Wild Cat Cave” while learning about important things like forgiveness, answered
              prayer and the value of honesty and obedience
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Image src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/elementor/thumbs/mbbs-vol2-omi197pbhgqvfddyzxptjdm8lvcbafpw8ohctmd994.jpg" />
          </Col>
          <Col md={8}>
            <p className="vol-title">
              <strong>Miss Brenda’s Bedtime Stories (VOL 2)</strong>
              <br />
              <em>Hardcover | 160 pages each | Full Color</em>
            </p>
            <p className="mt-3">
              Miss Brenda’s Bedtime Stories, <strong>Volume Two</strong>, is a collection of more than twenty-five
              delightful stories you will never forget. You are sure to enjoy “A Joke on Mama,” “Runaway Jumper” and
              “Bad, Bad Blackie” while learning about important things like forgiveness, answered prayer and the value
              of honesty and obedience.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Image src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/elementor/thumbs/mbbs-vol2-omi197pbhgqvfddyzxptjdm8lvcbafpw8ohctmd994.jpg" />
          </Col>
          <Col md={8}>
            <p className="vol-title">
              <strong>Miss Brenda’s Bedtime Stories (VOL 3)</strong>
              <br />
              <em>Hardcover | 160 pages each | Full Color</em>
            </p>
            <p className="mt-3">
              Miss Brenda’s Bedtime Stories, <strong>Volume Three</strong>, is a collection of more than twenty-five
              delightful stories—stories that you will never forget. You will enjoy “Creampuff’s Great Escape,” “Teddy
              to the Rescue” and “Code-Blue Night” while learning about important things like forgiveness, answered
              prayer and the value of honesty and obedience.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Image src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/elementor/thumbs/mbbs-vol4-omi19304jakftbksrdooowsxmxzh7y78k17xf8k848.jpg" />
          </Col>
          <Col md={8}>
            <p className="vol-title">
              <strong>Miss Brenda’s Bedtime Stories (VOL 4)</strong>
              <br />
              <em>Hardcover | 160 pages each | Full Color</em>
            </p>
            <p className="mt-3">
              Miss Brenda’s Bedtime Stories, <strong>Volume Four</strong>, is a collection of more than twenty-five
              delightful stories—stories that you will never forget. You are sure to enjoy the adventures of “The
              Missionary Raft,” the embarrassing “Piano Disaster” and the hard choice of “Keeping Promises” while
              learning about important things like forgiveness, answered prayer and the value of honesty and obedience.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Image src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/elementor/thumbs/mbbs-vol5-omi1914g5mhv63nj2cvfjxa0g68qsjzrvrwygon0go.jpg" />
          </Col>
          <Col md={8}>
            <p className="vol-title">
              <strong>Miss Brenda’s Bedtime Stories (VOL 5)</strong>
              <br />
              <em>Hardcover | 160 pages each | Full Color</em>
            </p>
            <p className="mt-3">
              Miss Brenda’s Bedtime Stories, <strong>Volume Five</strong>, is a collection of more than twenty-five
              delightful stories that you will never forget. You are sure to enjoy the thrilling story of “The Freak
              Wave,” the fascinating “Bird Brained” and a testimony of God’s faithfulness in “Pumpkin Pie Prayer” while
              learning about important things like forgiveness, answered prayer and the value of honesty and obedience.
            </p>
          </Col>
        </Row>
      </div>
    ),
  },
  'My Bible Friends': {
    desc: 'My Bible Friends is a Richly illustrated and beautifully written Children’s Bible story book set, especially designed for younger children. It will introduce your child to the wonderful stories of the Bible from a very early age. ',
    addInfo: (
      <div className="addInfo friends">
        <p>
          Richly illustrated and beautifully written, these books are definitely the premium set of Bible stories for
          younger children.
        </p>
        <ul>
          <li>Writtene specially for children ages 1 to 8</li>
          <li>Outstanding art fills each page opening from corner to corner</li>
          <li>
            Beautiful, yet simple language makes these books a pleasure to read time and time again (because you
            probably will)
          </li>
          <li>
            Each story is written using rhyme, rhythm, and repetition which helps children remember the stories and
            serves as a wonderful aid in teaching children child to read
          </li>
        </ul>
        <p>
          Imagine your child’s delight as you read the charming story of Small Donkey, who carried tired Mary up the
          long hill toward Bethlehem long ago. Or of Zacchaeus the cheater, who climbed a sycamore tree so he could see
          Jesus passing by. And then there’s the story of Baby Moses, fast asleep in his basket boat, carefully hidden
          in the tall rushes.
        </p>
        <p>
          These and many more Bible friends are waiting to greet your child within these beautifully illustrated books.
        </p>
        <ul>
          <li>Each book contains four simple stories, arranged in short, attention-holding sections.</li>
          <li>The durable bindings hold up to rough use by children.</li>
          <li>You can easily wipe sticky fingerprints from the colorful mylar covers.</li>
        </ul>
        <h3>The Stories Include</h3>
        <p>
          <b>Book One</b>
        </p>
        <ul>
          <li>Baby Moses</li>
          <li>Baby Jesus</li>
          <li>Joseph’s New Coat</li>
          <li>Joseph and His Brothers</li>
        </ul>
        <p>
          <b>Book Two</b>
        </p>
        <ul>
          <li>Jesus and the Storm</li>
          <li>Jesus and the Children</li>
          <li>Samuel, the Little Priest</li>
          <li>David, the Youngest Boy</li>
        </ul>
        <p>
          <b>Book Three</b>
        </p>
        <ul>
          <li>Go Wash in the River</li>
          <li>Elijah and the Time of No Rain</li>
          <li>A Room and a Boy</li>
          <li>Barley Loaves and Fishes</li>
        </ul>
        <p>
          <b>Book Four</b>
        </p>
        <ul>
          <li>Joash, the Boy King</li>
          <li>Esther, the Brave Queen</li>
          <li>When God Washed the World</li>
          <li>
            The Borrowed Ax
            <br />
          </li>
        </ul>
        <p>
          <b>Book Five</b>
        </p>
        <ul>
          <li>Daniel and His Friends</li>
          <li>Daniel and the Lions</li>
          <li>Zacchaeus the Cheater</li>
          <li>
            Jabel the Shepherd
            <br />
            <br />
            ISBN: 978-9996489471
            <br />
            Hardcover, 5-book set
            <br />
            Dimensions: 9.7 x 7.1 x 4 inches
          </li>
        </ul>
      </div>
    ),
  },
};

export default contents;
