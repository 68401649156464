import { REMOTE_URL } from '../common/api';

export const requestMoreInfo = async (formData) => {
  const result = await fetch(`${REMOTE_URL}/wp-json/prp_central/v1/form/getvalue`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return result;
};
