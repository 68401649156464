import React from 'react';
import { omitBy, values } from 'lodash';
import Modal from 'reactjs-popup';
import { FiX } from 'react-icons/fi';
import './styles.scss';

const LearnMore = (props) => {
  const { book, open, onClose, language } = props;
  var description_top = '';
  var description_bottom = '';
  if (open) {
    var split_desc = book.description.split('.');
    description_top = split_desc[0];
    description_bottom = values(omitBy(split_desc, (val, index) => index === 0)).join('.');
  }

  return (
    <div className="bg-white modal-inner-container">
      <div
        className="position-absolute cancel-icon"
        onClick={() => {
          onClose();
        }}
        tabIndex={0}
      >
        <FiX size={24} color="rgba(0,0,0,0.5)" />
      </div>
      <div className="bg-grey modal-top dsp-flx flx-1">
        <div className="flx-1 p-5">
          <img src={book?.cover || ''} className="modal-book-img" />
        </div>
        <div className="flx-5 pt-5 pl-5">
          <div className="dsp-flx">
            <p className="fnt-primary fnt-evolventa bold fnt-r-22 m-0">{book?.title}</p>
          </div>
          <div className="pr-5">
            <p className="fnt-jomolhari fnt-r-38 video-text-sub">{description_top}</p>
            <p className="fnt-14 fnt-poppins fnt-black">{description_bottom}</p>
          </div>
        </div>
      </div>
      <div className="modal-inner-bottom pt-5 pb-5">
        <div className="pb-4">
          <p className="fnt-primary fnt-r-22 bold fnt-evolventa">
            {language === 'eng' ? 'Table of Contents' : 'Tabla de contenido'}
          </p>
        </div>
        <div className="dsp-grid grid-col-2">
          {book.chapters.map((chapter, index) => (
            <div key={index}>
              <p className="fnt-r-14">{`${index + 1}.) ${chapter.title}`}</p>
            </div>
          ))}
        </div>
        <div className="pt-4">
          <a
            href="#request-more-section"
            className="bg-prime fnt-evolventa fnt-white fnt-r-14 bold border-none pr-4 pl-4 pt-3 pb-3"
            onClick={() => {
              onClose();
            }}
          >
            {language === 'eng' ? 'Get the set' : 'Relacionate con el Set'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
