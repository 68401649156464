import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Header from '../components/header/header';
import BooksContainer from '../components/dashboard/booksContainer';
import ScrollToTop from '../components/scrollToTop';
import CompactCard from '../components/cards/compactCards';
import RequestForm from '../components/RequestForm';
import otherbooks from '../data/staticTexts/homepage/texts';
import AuthStateProvider from '../components/authStateProvider';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import { RiArrowRightLine } from 'react-icons/ri';

export default function Home(props) {
  const { translations } = props.pageContext;
  const [showNav, setShowNav] = useState(true);
  const [prevScrollY, setPrevY] = useState(0);
  const { language } = useSelector((state) => state);
  const [staticText, setText] = useState(null);
  const [auidoSrc, setAudioSrc] = useState(
    language === 'eng'
      ? 'https://s3-us-west-2.amazonaws.com/ccc-auido-book/Book_1/Chapter+01+-+The+Fallen+Angel.mp3?_=1'
      : 'https://ccc-audios.s3.us-east-2.amazonaws.com/es/book-1/01-el-angel-caido.mp3'
  );

  const audioRef = useRef();
  useEffect(() => {
    const changedSrc =
      language === 'eng'
        ? 'https://s3-us-west-2.amazonaws.com/ccc-auido-book/Book_1/Chapter+01+-+The+Fallen+Angel.mp3?_=1'
        : 'https://ccc-audios.s3.us-east-2.amazonaws.com/es/book-1/01-el-angel-caido.mp3';

    setAudioSrc(changedSrc);

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  }, [language]);

  useEffect(() => {
    if (translations) {
      setText(translations[language]);
    }
  }, [translations]);

  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      const scrollPosition = window.scrollY;
      const scrollPosDiff = scrollPosition - prevScrollY;
      setPrevY(scrollPosition);
      if (scrollPosition < 107 || scrollPosDiff < 0) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [prevScrollY]);

  useEffect(() => {
    setText(translations[language]);
  }, [language]);

  return (
    <AuthStateProvider authRequired={false} id="outer-wrapper" className="main-container" {...props}>
      <Header show={showNav} />
      {staticText && (
        <div id="page-wrap" className="m-0 p-0">
          <SEO title="Home" />
          <section id="dashboard-banner">
            <div className="dash-banner-container dsp-flx flx-center">
              <div className="dashboard-inner dsp-flx">
                <div className="dashboard-top-banner">
                  <h1 className="banner-text-pre-header fnt-primary fnt-r-22 bold fnt-evolventa mb-3">
                    {staticText.top.title}
                  </h1>
                  <h2 className="banner-text-header fnt-evolventa mb-2">{staticText.top['lg_title']}</h2>
                  <h2 className="banner-text-sub fnt-jomolhari fnt-white mb-4">{staticText.top['md_title']}</h2>
                  <p className="banner-text-description fnt-poppins">{staticText.top['description']}</p>
                  <div className="pt-4 hide-mobile">
                    <a
                      href="#request-more-section"
                      className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none"
                    >
                      {staticText.top['btn']}
                    </a>
                  </div>
                </div>
                <div className="dashboard-banner-image flx-1">
                  <img
                    src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set.png"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                    srcSet="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set.png 600w, https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set-279x300.png 279w"
                    sizes="(max-width: 600px) 100vw, 600px"
                  />
                  <div className="position-absolute dash-bg-container">
                    <img
                      src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Book-bg.svg"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="mt-5 hide-desktop">
                  {/* need to work here */}
                  <a className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none">
                    {staticText.top['btn']}
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="dsp-flx flx-center">
            <div className="books-container">
              <BooksContainer {...props} staticText={staticText['section_books']} language={language} />
            </div>
          </section>
          <section id="dash-audio-section">
            <div className="audio-container dsp-flx">
              <div className="audio-image dsp-flx flx-1">
                <img
                  className="resp-img"
                  src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Audio-Book.svg"
                  alt=""
                />
              </div>
              <div className="dsp-flx audio-text flx-1">
                <div>
                  <h2 className="fnt-primary fnt-r-22 bold fnt-evolventa">{staticText['section_audio'].title}</h2>
                  <h3 className="fnt-jomolhari fnt-r-38 fnt-black mb-3">{staticText['section_audio'].lg_title}</h3>
                  <p className="fnt-poppins fnt-r-18 audio-sub-text mb-3">{staticText['section_audio'].description}</p>

                  <audio controls controlsList="nodownload" ref={audioRef}>
                    <source src={auidoSrc} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>

                  <div className="hide-mobile"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="dsp-flx flx-center">
            <div className="app-container">
              <div className="mb-3">
                <h2 className="fnt-evolventa bold fnt-r-22 m-0">
                  <span className="fnt-primary">{staticText['section_app'].title}</span>{' '}
                  <span className="fnt-black">{staticText['section_app'].subtitle}</span>
                </h2>
              </div>
              <div className="">
                <h3 className="fnt-jomolhari fnt-r-38 app-text-sub">{staticText['section_app'].lg_title}</h3>
              </div>
              <div className="img-cover">
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Image_with_overlay-1024x457.png"
                  className="attachment-large size-large"
                  alt=""
                  loading="lazy"
                  srcSet="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Image_with_overlay-1024x457.png 1024w, https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Image_with_overlay-300x134.png 300w, https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Image_with_overlay-768x343.png 768w, https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Image_with_overlay.png 1108w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
                <div className="img-cover-overlay dsp-flx">
                  <div className="app-overlay-text">
                    <p className="fnt-poppins fnt-white m-0">{staticText['section_app'].description}</p>
                  </div>
                  <div className=" app-overlay-btn">
                    <a
                      href="#request-more-section"
                      className="inner-btn bg-prime fnt-evolventa fnt-white fnt-r-14 bold border-none p-2"
                    >
                      {staticText['section_app'].btn}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="sample-book">
            <div className="sample-container dsp-flx">
              <div className="sample-inner-container dsp-flx flx-1">
                <div className="w-70">
                  <h2 className="fnt-primary fnt-evolventa bold fnt-r-26 mb-2">{staticText['section_sample'].title}</h2>
                  <h2 className="fnt-black fnt-evolventa bold fnt-r-26 mb-3">
                    {staticText['section_sample'].subtitle}
                  </h2>
                  <h3 className="fnt-jomolhari fnt-r-38 fnt-black m-0">{staticText['section_sample'].lg_title}</h3>
                  <h3 className="fnt-jomolhari fnt-r-38 fnt-black mb-4">{staticText['section_sample'].lg_title_b}</h3>
                  <a href="https://api.childrenscenturyclassics.com/flipbook/flipbook.php#p=1">
                    <button className="bg-prime fnt-evolventa fnt-white fnt-14 bold border-none px-3 py-2 open-flibbook btn btn-primary">
                      {`${language === 'eng' ? 'Open' : 'Abrir'}`} <RiArrowRightLine size={18} color="#fff" />
                    </button>
                  </a>
                </div>
              </div>
              <div className="sample-img-container flx-1 mb-2">
                <img
                  src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/book-e1573315082529.png"
                  className="attachment-large size-large"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </section>
          <section className="dsp-flx flx-center">
            <div className="video-container">
              <div className="video-inner">
                <div className="dsp-flx mb-3 mt-2">
                  <h2 className="fnt-primary fnt-evolventa bold fnt-r-22 m-0">{staticText['section_video'].title}</h2>
                </div>
                <div className="pr-5">
                  <h3 className="fnt-jomolhari fnt-r-38 video-text-sub">{staticText['section_video'].lg_title}</h3>
                </div>
                <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <div
                    className="wistia_responsive_wrapper"
                    style={{
                      height: '100%',
                      left: '0',
                      position: 'absolute',
                      top: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      className={`wistia_embed wistia_async_wikxd59i1h ${language !== 'eng' ? 'hidden-video' : ''}`}
                      style={{ width: '100%', height: '100%' }}
                    >
                      &nbsp;
                    </div>

                    <div
                      className={`wistia_embed wistia_async_9s3afye8n5 ${language !== 'esp' ? 'hidden-video' : ''}`}
                      style={{ width: '100%', height: '100%' }}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
              <div className="other-book-container mt-2 mb-2">
                <Row>
                  {otherbooks[language].map((otherBook) => (
                    <Col key={`other-book-${otherBook.id}`} md={3}>
                      <CompactCard
                        cover={otherBook.cover}
                        title={otherBook.title}
                        description={otherBook.description}
                        language={language}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="bg-white request-more-inner" id="request-more-section">
                <div className="mb-4">
                  <h2 className="fnt-evolventa bold fnt-r-26 m-0">
                    <span className="fnt-primary">{staticText['section_form'].title}</span>
                    <br />
                    <span className="fnt-black">{staticText['section_form'].sub_title}</span>
                  </h2>
                </div>
                <RequestForm staticText={staticText['section_form']} language={language} />
              </div>
            </div>
          </section>
          <section>
            <Footer />
          </section>
          <ScrollToTop show={!showNav} />
        </div>
      )}
    </AuthStateProvider>
  );
}
