import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { RiArrowRightLine } from 'react-icons/ri';
import { Modal, Row, Col } from 'react-bootstrap';
import contents from './contents';

const CompactCard = (props) => {
  const { cover, title, description, language } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="bg-white other-inner">
      <div className="text-center">
        <Image src={cover} alt="" />
      </div>
      <div className="other-books-texts mt-3">
        <h2 className="fnt-evolventa fnt-r-20 fnt-light-grey bold mb-3">{`${title}`}</h2>
        <p className="fnt-poppins fnt-14 fnt-black">{`${description}`}</p>
      </div>
      {language === 'eng' ? (
        <>
          <div className="mt-3">
            <a onClick={handleShow} className="dsp-flx flx-row align-items-center" role="button">
              <p className="fnt-primary bold fnt-evolventa m-0 pr-2">
                {language === 'eng' ? 'Learn More' : 'Aprende más'}
              </p>
              <RiArrowRightLine size={18} color="#FC650A" />
            </a>
          </div>
          <Modal show={show} onHide={handleClose} size="lg" className="other-inner-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                <Row>
                  <Col md={4}>
                    <Image src={cover} alt="" />
                  </Col>
                  <Col md={8}>
                    <h2>{title}</h2>
                    <p className="mt-4 mb-2">{contents[title].desc}</p>
                  </Col>
                </Row>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="fnt-primary">Additional Infomation</p>
              <div className="elementor-divider">
                <span className="elementor-divider-separator"></span>
              </div>
              {contents[title].addInfo}
            </Modal.Body>
            <Modal.Footer>
              <a href="#request-more-section" onClick={handleClose} className="btn btn-primary">
                Get the Set
              </a>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default CompactCard;
