import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './style.scss';

const ScrollToTop = (props) => {
  if (props.show) {
    return (
      <div className="scroll-top-container">
        <div
          className="cursor-pointer auto-scroll-container"
          tabIndex={1}
          role="button"
          onKeyDown={() => {}}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          <FaArrowUp size={18} />
        </div>
      </div>
    );
  }

  return null;
};

export default ScrollToTop;
