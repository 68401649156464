import React from 'react';
import { RiArrowRightLine } from 'react-icons/ri';

const Book = (props) => {
  const { book, onClick, language } = props;

  return (
    <div className="indi-book">
      <div className="indi-img">
        <img src={book.cover} className="dash-book-img" />
      </div>
      <div className="mt-4">
        <h4 className="bold fnt-evolventa fnt-light-grey mb-1">{book.title}</h4>
        <a
          href="#"
          className="dsp-flx flx-row align-items-center"
          role="button"
          onClick={() => {
            onClick(book.id);
          }}
        >
          <p className="fnt-primary bold fnt-evolventa m-0 pr-2">{language === 'eng' ? 'Learn More' : 'Aprende más'}</p>
          <RiArrowRightLine size={18} color="#FC650A" />
        </a>
      </div>
    </div>
  );
};
export default Book;
