import BibleClassic from '../../../assets/png/bible_classic.jpg';
import FamilyBible from '../../../assets/png/family_bible.jpg';

export default {
  eng: [
    {
      id: '1',
      cover: BibleClassic,
      title: 'Bible Reference Century Classics',
      description: 'The most complete and insightful commentary on the Bible, recommended for all ages.',
    },
    {
      id: '2',
      cover: FamilyBible,
      title: 'Family Bible',
      description:
        'This Family Bible is no ordinary Bible – it is a beautiful heirloom that your family will treasure for generations.',
    },
    {
      id: '3',
      cover:
        'https://api.childrenscenturyclassics.com//wp-content/uploads/elementor/thumbs/miss_brenda-omi19ceipq1je1rl82ahgand46o4pw9glqdc3yhk1m.png',
      title: "Miss Brenda's Bedtime Stories",
      description:
        'A five-volume collection of twenty-five delightful stories per volume – stories that you will never forget.',
    },
    {
      id: '4',
      cover:
        'https://api.childrenscenturyclassics.com//wp-content/uploads/elementor/thumbs/mbf_set-e1530466417681-ogsy0occkyfwbe992zowg5qd4gm79jwwadaqwluv22.png',
      title: 'My Bible Friends',
      description:
        'A five volume set richly illustrated and beautifully written. A premium set of Bible stories for younger children.',
    },
  ],
  esp: [
    {
      id: '1',
      cover: BibleClassic,
      title: 'Clásicos del siglo de referencia bíblica',
      description: 'El comentario más completo y profundo de la Biblia, recomendado para todas las edades.',
    },
    {
      id: '2',
      cover: FamilyBible,
      title: 'Biblia familiar',
      description:
        'Esta Biblia familiar no es una Biblia común; es una hermosa reliquia que su familia atesorará durante generaciones.',
    },
    {
      id: '3',
      cover:
        'https://api.childrenscenturyclassics.com//wp-content/uploads/elementor/thumbs/miss_brenda-omi19ceipq1je1rl82ahgand46o4pw9glqdc3yhk1m.png',
      title: 'Cuentos para dormir de la señorita Brenda',
      description:
        'Una colección de cinco volúmenes de veinticinco historias encantadoras por volumen, historias que nunca olvidará.',
    },
    {
      id: '4',
      cover:
        'https://api.childrenscenturyclassics.com//wp-content/uploads/elementor/thumbs/mbf_set-e1530466417681-ogsy0occkyfwbe992zowg5qd4gm79jwwadaqwluv22.png',
      title: 'Mis amigas de la biblia',
      description:
        'Un juego de cinco volúmenes ricamente ilustrado y bellamente escrito. Un conjunto premium de historias bíblicas para niños más pequeños.',
    },
  ],
};
